.shareSection{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    /* width: 95%; */
    background: #252525;
    height: 350px;
    display: flex;
    justify-content: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: 0 auto;
    padding: 15px 0;
}
.searchInput,.searchInput:focus{
    box-shadow: none;
    border:1px solid #252525;
    background:#414141 !important;
    color: white;
}
.searchBtn,.searchBtn:hover{
    background: #414141;
    border: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    top: 1px;
    left: -2px;
}
.searchHint{
    position: absolute !important;
    background: #252525;
    top: 55px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.searchHint svg{
    width: 70px;
    height: 70px;
}
.searchHintText{
    letter-spacing: .5px;
    font-family: "outfitRegular";
    text-align: center;
}
.videoCardContainer{
    height: 255px;
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    /*background: #db711970;*/
}
.videoCard{
    display: flex;
    margin: 15px;
    background: #9b949452;
    position: relative;
    box-shadow: 0px 0px 2px 0 #ffca0a;
}
.cardImageContainer{
    width: 55px;
    height: 100px;
}
.cardImageContainer img{
    width: 55px;
}
.suggestedTopicsContainer{
    position: absolute !important;
    background: #dadada;
    top: 55px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    z-index: 1;
}
.suggestedTopicsUl{
    list-style-type: none;
    width: 55%;
    padding: 1% 5%;
    background: #c1bbbb;
    border-radius: 5px;
    max-height: 200px;
    overflow: auto;
}
.suggestedTopicsUl > li {
    margin: 4% 0;
    line-height: 29px;
    cursor: pointer;
}
.cardDetailsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
}
.w100{
    width: 100%;
}
.videoCardTitle{
    font-size: 14px;
    letter-spacing: .4px;
    color: white;
}
.videoCardSubTitle{
    font-size: 12px;
    letter-spacing: .2px;
    color: white;
}
.cardBtn{
    font-family: "rifficBold";
    color: white;
    background-color: #3d3c3a;
    padding: 6px 5px;
    border-radius: 25px;
    border: none;
    transition: .1s ease;
    box-shadow: 0px 2px 4px 1px #5c5555c4;
    transition: background-color 200ms linear;
    margin-left: 15px;
    box-shadow: 0 3px 3px 0 #ffca0a;
}
.cardBtn:active{
    transform: translateY(2px);
    box-shadow: none;
    border: 1px solid black;
}