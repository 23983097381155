.mainContainer{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* padding-top: 3rem; */
    /* height: auto; */
    /* min-height: 80vh; */
    /* height: 85vh; */
    padding-top: 1rem;
    padding-bottom: 15px;
    overflow-y: auto;
    overflow-x: hidden;
}
.backToVideosBtnContainer{
    display: flex;
    position: absolute;
    top: 15px;
    left: 15px;
    margin-bottom: 0 !important;
    cursor: pointer;
}
.leftSide{
    font-family: "rifficBold";
    font-weight: bold;
    color: white;
    text-decoration: none;
    font-size: 18px;
    margin-right: 5px;
    background: #434039;
    padding: 7px 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    cursor: pointer;
}
.rightSide{
    font-family: "rifficBold";
    font-weight: bold;
    color: white;
    text-decoration: none;
    font-size: 18px;
    /* margin-left: 5px; */
    background: #434039;
    padding: 7px 23px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    cursor: pointer;
}
.activeLink{
    color: #db7119 !important;
  }
.vidoesContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 100%;
    min-height: 280px;
    width: auto;
    min-width: 300px;
    max-width: 500px;
    /* border: 2px solid #434039;
    background-color: #434039; */
    margin: 0 15px 0 0;
    overflow-y: scroll;
    /* -ms-overflow-style: none;  */
    scrollbar-width: none;   
    transition: min-height 0.25s ease-in,min-width 0.25s ease-in;
}
.vidoesContainer2{
    position: fixed;
    top:50px;
    bottom: 70px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    min-height: 280px;
    width: 100vw;
    /* max-width: 500px; */
    /* border: 2px solid #434039; */
    background-color: #191919;
    margin:0;
    padding: 5px;
    overflow-y: scroll;
    /* -ms-overflow-style: none;  */
    scrollbar-width: none;   
    transition: min-height 0.25s ease-in,min-width 0.25s ease-in;
}
.vidoesContainer::-webkit-scrollbar,.vidoesContainer2::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.videoLink{
    /* flex: 0 0 50%; */
    height: 280px;
    width: 40vw;
    margin: 5px;
    /* background-color: green; */
    /* padding: 1px; */
    border: 1px solid #000;
}
.videoLink2{
    height: 250px;
    width: 30vw;
    margin: 5px;
    border: 1px solid #000;
}
.videoImg{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.backToVidesoButton{
    max-width: 300px;
    min-width: 270px;
    width: 300px;
    font-size: 18px;
    font-family: "rifficBold";
    color: white;
    background: #3d3c3b;
    padding: 15px;
    width: 100%;
    border-radius: 25px;
    box-shadow: 0px 6px 0 0 #b57c28;
    border: none;
    transition: .5s ease;
}
.watchVideoContainer{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 46px;
    background-color: black;
    /* z-index: -1; */
    width: 100%;
    overflow: scroll;
    height: 100vh;
    scroll-snap-type: y mandatory;
}
.videoCard_player{
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 45px;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}
.videoCard_player_shared{
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 45px;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}
.playBtnDiv{
    position: absolute;
    z-index: 9;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3e3f3e;
    border-radius: 50%;
}
#playBtnSvg{
    position: absolute;
    z-index: 9;
    width: 60px;
    height: 60px;
    top: 50%;
    left: 55%;
    transform: translate(-50%,-50%);
}
#playBtnSvg path{
    fill: white;
}
.playText{
    position: relative;
    text-align: center;
    z-index: 9;
    width: 50px;
    height: 50px;
    color: white;
    align-self: end;
    /* text-shadow: 0px 4px 5px #000, 0 0 5px #000; */
    text-shadow:  0px 3px 0px #db7119, 0 4px 0px #fff, 0 0 30px #fff0, 0 0 40px #ff00de00, 0 0 70px #ff00de00, 0 0 80px #ff00de00, 0 0 100px #ff00de00, 0 0 150px #ff00de00;
    letter-spacing: 1.2px;
}
.watchSavedVideoContent{
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
    object-fit: unset;
}

.slideOutDown {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    height: 150px;
    display: flex;
    justify-content: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: 0 auto;
    padding: 15px;
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes slideOutDown {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        visibility: hidden;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}
@keyframes slideOutDown {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        visibility: hidden;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}
.slideInUp {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    height: 150px;
    display: flex;
    justify-content: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: 0 auto;
    padding: 15px;
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes slideInUp {
    0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    }
    }
    @keyframes slideInUp {
    0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    }
    } 
    @media screen and (min-width: 375px) {
        .vidoesContainer{
            min-width: 95%;
        }
    }
    @media screen and (min-width: 538px) {
        .vidoesContainer{
            min-width: 500px;
        }
    }
    @media screen and (min-width: 598px) {
        .videoLink{
            width: 239px;
        }
        .videoLink2{
            width: 176px;
        }
    }
