@font-face {
    font-family: rifficBold;
    src: url(./riffic/RifficFree-Bold.ttf);
    font-weight: bold;
  }
@font-face {
    font-family: rifficRegular;
    src: url(./riffic/Riffic.ttf);
    font-weight: bold;
}
@font-face {
  font-family: outfitRegular;
  src: url(./outfit/Outfit-Regular.ttf);
  font-weight: bold;
  }

@font-face {
  font-family: outfitBold;
  src: url(./outfit/Outfit-Bold.ttf);
  font-weight: bold;
  }

@font-face {
  font-family: open_sans_regular;
  src: url(./open-sans/OpenSans-Regular.ttf);
  /* font-weight: bold; */
  }
@font-face {
  font-family: open_sans_bold;
  src: url(./open-sans/OpenSans-Bold.ttf);
  /* font-weight: bold; */
  }
