#sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    height: 100vh;
    width: 250px;
    padding: .7em;
    background-color: #252525;
    transition: all 0.3s ease;
    z-index: 999;
    user-select: none;
  }
  #sidebar.show {
    left: 0;
  }
main{
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: margin-top 0.25s ease-in;
}
.profileSection{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.imgWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px; 
    margin: 5px;
    display: flex;
}
.imgWrap img{
    width: 70px;
    height: 70px;
}
#editProfileAliceCarouselWrapper{
    width: 120px;
    position: relative;
}
.formSection{
    width: 80%;
}
.editForm > input{
    margin-top: 15px;
}
#genderWraper{
    font-size: 18px;
    margin-top: 15px;
    color: white;
    text-align: center;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: column;
}
#genderWraper > label > input {
    margin-right: 5px;
}
.buttonSection{
    margin-top: 3rem;
    /* width: 80%; */
}

.backToVideos{
    color: #FFCA0A;
    letter-spacing: 1.2px;
    background-color: #252525;
    padding: 10px 42px;
    width: 100%;
    min-width: 46%;
    max-width: 100%;
    border-radius: 25px;
    border: none;
    transition: .1s ease;
    box-shadow: 0px 6px 0px 0px #3c3a3a9c;
  }


  .backToVideos:active{
    transform: translateY(4px);
    box-shadow: none;
  }
  @media screen and (min-width: 598px) {
    .formSection{
        width: 598px;
    }
  }
  @media screen and (min-height: 760px) {
    .main{
        margin-top: 30px
    }
  }
  @media screen and (min-height: 830px) {
    .main{
        margin-top: 45px
    }
  }